html {
    height: 100%;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body {
    background-color: #fff;
    font-size: 14px;
    color: #0066a1;
    position: relative;
    margin: 0;
    padding-bottom: 6rem;
    min-height: 100%;
    font-family: verdana,arial,sans-serif;
}

table tr td a, table tr td p, table tr td span {
    min-height: 44px;
}

#Project-MyProject table tr td a, table tr td p, table tr td span {
    max-height: 2em;
    overflow: hidden;
}

.h4 {
    font-size: 1.5em;
}

td > span.h4 {
    margin-left: -10px;
}

.container {
    margin: 0 auto;
}

td a {
    display: block;
}

a:focus {
    outline: none;
}

a:hover {
    text-decoration: none;
}

.bold {
    font-weight: 600;
}

.non-existent a, .nav-tabs.nav-justified li.non-existent a:hover, .nav-tabs.nav-justified li.non-existent a:focus, .nav-tabs.nav-justified li.non-existent a:active {
    cursor: not-allowed;
    background-color: #DADCDF;
}

.dropdown-menu > .disabled > a:focus, .dropdown-menu > .disabled > a:hover {
    color: #DADCDF;
}

.body-content {
    margin-top: 50px;
    padding-left: 15px;
    padding-right: 15px;
}

/* -----------------------------------------------*/
/* ---------->>> BOOTSTRAP OVERRIDE <<<-----------*/
/* -----------------------------------------------*/

.panel-body > .row.panelBugfix {
    margin: 0px;
}

.row {
    margin-top: 20px;
}

label.CustomLabel {
    margin-top: 20px;
}


textarea {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075);
}

.dl-horizontal {
    -ms-word-wrap: break-word;
    word-wrap: break-word;
}

    .dl-horizontal dt {
        white-space: normal;
        text-align: left;
    }

.glyphicon:hover {
    text-decoration: none;
    cursor: pointer;
}

td a.glyphicon {
    float: right;
    padding-left: 10px;
}

td a:hover {
    text-decoration: none;
}

td .glyphicon {
    margin-left: 10px;
}

a.glyphicon {
    text-decoration: none;
}

table a:hover {
    text-decoration: none;
}

.dummy {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    user-select: none;
    opacity: 0.9;
    color: grey;
    font-style: italic;
    display: block;
}

.padding-left-10 {
    padding-left: 10px;
}

.padding-right-50 {
    padding-right: 50px;
}

@media(min-width:992px) {
    .padding-left-80-minus-lg {
        margin-left: -80px;
    }
}

.padding-right-10 {
    padding-right: 50px;
}

.padding-top-10 {
    padding-top: 10px;
}

.padding-top-100 {
    padding-top: 100px;
}

.padding-top-5 {
    padding-top: 5px;
}

.padding-top-28 {
    padding-top: 28px;
}

.padding-bottom-10 {
    padding-bottom: 10px;
}

.padding-bottom-50 {
    padding-bottom: 50px;
}

.padding-all-10 {
    padding: 10px;
}

#Download-Button {
    float: left;
    width: 122px;
}

#Download-All-Button {
    float: left;
    width: 120px;
    margin-left: 10px;
}

#resetConfiguration {
    float: left;
    font-size: 16px;
    color: #fff;
    padding: 12px;
}

    #resetConfiguration:hover {
        float: left;
        font-size: 16px;
        color: #fff;
        text-decoration: underline;
        padding: 12px;
    }

.Configurator-All-Button-Big {
    max-width: 460px;
    /*width: 260px;*/
    display: block;
    font-size: 12px;
    padding-left: 0;
}

#Configurator-All-Button-Small {
    width: 280px;
    display: block;
    font-size: 10px;
}

.led-badge {
    position: absolute;
    top: -20px;
    right: 30px;
}

.template-badge {
    position: absolute;
    top: 0px;
    right: 30px;
}

.glyphProjectImg {
    margin-left: 60px;
    margin-top: -30px;
}

.glyphicon-sort:hover, .glyphicon-sort:focus, .glyphicon-sort:active {
    cursor: move;
}

span.download-label {
    display: table-cell;
    vertical-align: middle;
    padding-left: 7px;
}

span.download-label_newLayout {
    display: inline;
    vertical-align: middle;
    padding-left: 0px;
}
label.download-label_newLayout{
    display: inline;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: bold;
}

span.checkbox-download-label {
    padding-top: 5px;
    font-weight: 400;
}

span.project-table {
    cursor: pointer;
}

    span.project-table:hover {
        cursor: pointer;
        text-decoration: underline;
    }

.fst-oblique {
    font-style: oblique;
}

.fst-italic {
    font-style: italic;
}

.brdr-right-none {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}

.max-width-none {
    max-width: none;
}

.height-40px {
    height: 40px;
}

.height-200px {
    height: 200px;
}

textarea.height-200px {
    height: 200px;
}

.width-max-450px {
    max-width: 450px;
}

input.width-max-450px {
    max-width: 450px;
}

textarea.width-max-450px {
    max-width: 450px;
}

#Searchgroup .dropdown-menu {
    /*margin-top: 0;*/
    /*width: 100%;*/
}

input,
select,
textarea {
    max-width: 280px;
}

#ProjectEdit input, #ProjectEdit textarea, #ProjectEdit select {
    max-width: 100%;
}

    #ProjectEdit textarea.form-control {
        height: 70px;
    }

.col-md-3-custom {
    position: relative;
    min-height: 1px;
    padding-left: 15px;
    width: 20%;
}

.bs-searchbox .form-control {
    max-width: 100%;
}

.lisa-search-highlight {
    background: lightblue;
    vertical-align: top;
}

#term_suggestion > .e-content:before {
    content: "Vorgeschlagene Ergebnisse";
    margin: 5px;
    font-size: 16px;
    font-weight: 600;
    color: #337AB7;
}

#term_suggestion li div.row {
    margin-top: 2px;
}

p.lisa-search-text {
    text-overflow: inherit;
    overflow: inherit;
    white-space: normal;
    height: auto;
    max-height: 100%;
    color: black;
}

.e-hover p.lisa-search-text {
    text-overflow: inherit;
    overflow: inherit;
    white-space: normal;
    height: auto;
    max-height: 100%;
    cursor: pointer;
}

.lisa-search-text {
    vertical-align: top;
    white-space: normal;
}

.lisa-search-image-overlay {
    height: 100px;
    /*width: 100%;*/
    position: relative;
}

.lisa-search-image {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
}

a.lisa-search-emptylink[href=""] {
    display: none;
    height: 0;
    visibility: collapse;
}

.lisa-search-input-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    height: 100%;
    min-height: 100%;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin-right: 20px;
    height: 40px;
}

    .lisa-search-input-group > * {
        border-radius: 0px;
        height: 100%;
    }

.lisa-search-settings-dropdown-btn {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    height: 100%;
}

    .lisa-search-settings-dropdown-btn > input {
        display: none;
    }

        .lisa-search-settings-dropdown-btn > input:checked ~ .lisa-search-option-dropdown {
            display: block;
        }

    .lisa-search-settings-dropdown-btn > label {
        margin: auto;
        -webkit-border-top-left-radius: 0px;
        -webkit-border-bottom-left-radius: 0px;
        -moz-border-radius-topleft: 0px;
        -moz-border-radius-bottomleft: 0px;
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        -webkit-border-top-right-radius: 5px;
        -webkit-border-bottom-right-radius: 5px;
        -moz-border-radius-topright: 5px;
        -moz-border-radius-bottomright: 5px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        width: 50px;
        height: 100%;
    }

.lisa-search-option-dropdown {
    display: none;
    width: 0;
    height: 0;
    margin: 0;
    position: relative;
}

    .lisa-search-option-dropdown > dl {
        position: absolute;
        background: white;
        right: 0;
        width: 160px;
        padding: 5px;
        margin: 0;
        -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
        border-top-right-radius: 5px;
        z-index: 101;
    }

        .lisa-search-option-dropdown > dl > dd {
            padding: 5px;
        }

    .lisa-search-option-dropdown input[type="radio"] {
        display: none;
    }

        .lisa-search-option-dropdown input[type="radio"] ~ .glyphicon-ok {
            display: none;
        }

        .lisa-search-option-dropdown input[type="radio"]:checked ~ .glyphicon-ok {
            display: inline;
        }

    .lisa-search-option-dropdown label {
        margin: 0px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
        -ms-flex-pack: justify;
        justify-content: space-between;
        -ms-user-select: none;
        -moz-user-select: none;
        -webkit-user-select: none;
        user-select: none;
    }


/* -----------------------------------*/
/* ---------->>> GLOBAL <<<-----------*/
/* -----------------------------------*/
.lisa-hiddenonly {
    visibility: hidden;
}

.bgColorWhite {
    background-color: #fff;
}

.text-shadow {
    text-shadow: 0 0 4px rgba(0,0,0,0.2);
}

.no-margin {
    margin: 0;
}

.no-padding {
    padding: 0;
}

.customFileInput {
    float: right;
    padding: 0 5px;
}

.label-anchor {
    color: #3C3C3C;
    font-weight: 500;
    display: block;
    cursor: pointer;
    padding: 10px;
    margin-bottom: 0;
    font-size: 12px;
}

    .label-anchor:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }

button.no-button {
    background-color: #fff;
    border: none;
    padding: 10px;
    font-weight: 400;
    color: #3C3C3C;
    font-size: 12px;
    display: block;
    white-space: nowrap;
    width: 100%;
    text-align: left;
}

    button.no-button:hover {
        color: #262626;
        text-decoration: none;
        background-color: #f5f5f5;
    }

span#Resize-Textarea {
    position: absolute;
    right: 19px;
    top: 5px;
    opacity: 0.8;
    display: none;
}

.crsr-default {
    cursor: default;
}

div#Multi-download.scroll-to-fixed-fixed {
    background-color: #fff;
    border-radius: 5px;
}

div#Multi-download {
    padding: 5px;
    padding-top: 50px;
}

#multi-download li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    margin-top: 5px;
}

    #multi-download li > div {
        width: 100%;
    }

        #multi-download li > div > input[type="checkbox"] {
            margin-right: 5px;
        }

.download-label {
    margin-top: 5px;
    cursor: pointer;
    color: #0066a1;
}

    .download-label:focus, .multi-download-check:focus, .multi-download-check-all:focus, #multi-download li:focus {
        outline: none;
    }

.font-size-34px {
    font-size: 34px;
}

.font-size-16px {
    font-size: 16px;
}

#TreeNodeCode {
    display: block;
    font-weight: 600;
}

.treeNodeProductImage {
    width: 100px;
    height: 108px;
}

span[data-lisa_countnumber]:after {
    content: " (" attr(data-lisa_countnumber) ")";
}

ul#memberList {
    -ms-word-break: break-all;
    word-break: break-all;
    padding-top: 20px;
}

#memberList {
    margin-bottom: 20px;
}

td.headerActions {
    float: right;
}

.growBig {
    font-size: 3em;
}

.grow {
    font-size: 2em;
}

.growHalf {
    font-size: 1.5em;
}

.growHalfCustom {
    color: #337AB7;
    font-size: 21px;
}

    .growHalfCustom:hover {
        font-size: 21px;
        color: #23527C;
    }

.growQuarter {
    font-size: 1.25em;
}

.dp-block {
    display: block;
}

.ta-right {
    text-align: right;
}

.ta-left {
    text-align: left;
}

.ta-center {
    text-align: center;
}

.product {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: hidden;
    margin-bottom: 2em;
    height: 210px;
    width: 185px;
}

.InfoTooltipTouch {
    position: absolute;
    padding: 20px;
    left: -20px;
    top: -20px;
}

.product img {
    display: block;
    max-height: 100%;
    max-width: 100%;
    vertical-align: middle;
}

.product-caption {
    width: 80%;
    height: auto;
    color: #0066A1;
    -ms-word-break: normal;
    word-break: normal;
}

.product:hover {
    -webkit-transform: scale(1.03);
    transform: scale(1.03);
    z-index: 99;
}

    .product:hover .product-caption {
        color: #003655;
        text-decoration: none;
    }

.product .h4 {
    font-size: 14px;
}

.product:hover .product-caption span.h4 {
    text-decoration: none;
}

.product-image {
    position: relative;
    height: 120px;
    width: 120px;
}

    .product-image img {
        max-height: 100%;
        max-width: 100%;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
    }

.myProjectProductImage img, .myProjectProductImage {
    max-height: 100%;
    max-width: 100%;
}

div#MyProjectAreas {
    padding-left: 0;
}

div#MyProjectProjectResults {
    height: auto;
    -webkit-box-shadow: 0 3px 8px rgba(0,0,0,0.4);
    box-shadow: 0 3px 8px rgba(0,0,0,0.4);
    padding: 0;
}

    div#MyProjectProjectResults #ProjectResultHeader {
        width: 100%;
        color: #337AB7;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
        padding: 10px 10px 0 10px;
    }

    div#MyProjectProjectResults #ProjectResultContent {
        padding: 5px 10px 10px 10px;
    }

#ProjectResultContent hr {
    margin: 0;
    margin-bottom: 10px;
}

div#MyProjectProjectResults #ProjectResultContent .projectResultLink {
    margin-bottom: 5px;
}

#ProjectResultsDownloadsHeader {
    font-size: 1.2em;
}

div#ActionsBox.sticky {
    margin-top: 5px;
}

    div#ActionsBox.sticky .glyphicon:hover {
        color: #23527C;
    }

div#ProductActionsBox.sticky {
    margin-top: 5px;
}

    div#ProductActionsBox.sticky .glyphicon:hover {
        color: #23527C;
    }

#ActionsBox.sticky .dropdown-menu > li.disabled > a, .dropdown-menu > .disabled > a {
    color: #DADCDF;
}

.details-product-image {
    position: relative;
    width: 100%;
}

    .details-product-image img {
        max-width: 100%;
        max-height: 100%;
    }

.productImageWrapper {
    height: 120px;
    width: 120px;
}

.header-product-image {
    position: relative;
    width: 250px;
    height: 300px;
    padding: 3px;
    max-width: 100%;
}

    .header-product-image img {
        display: inline-block;
        vertical-align: middle;
        max-height: 100%;
        max-width: 100%;
        min-width: 140px;
    }

.btn-product {
    max-width: 165px;
}

.product-actions .glyphicon {
    padding: 0.5em;
}

span.glyphicon.glyphicon-ok.growHalf.memorize {
    color: #EAEDF1;
    cursor: pointer;
}

    span.glyphicon.glyphicon-ok.growHalf.memorize.check {
        color: #449d44;
    }

/*span.glyphicon.glyphicon-ok[id$='ValidCheckerGreen']{*/
#ValidCheckerGreen {
    cursor: default;
    padding: 12px;
    float: left;
    font-size: 20px;
    color: #fff;
}
/*span.glyphicon.glyphicon-remove[id$='ValidCheckerOrange']{*/
#ValidCheckerDefault {
    cursor: default;
    padding: 12px;
    float: left;
    font-size: 20px;
    color: #fff;
}

.ValidCheckerDefaultLabel {
    float: left;
    font-size: 16px;
    color: #fff;
    padding: 12px;
}


.myProjectProduct, .product-image {
    position: relative;
}

    .myProjectProduct hr {
        margin: 0;
    }

.generalActions {
    background-color: #337AB7;
    margin: 0 0 20px -10px;
}

.generalActionConfigurator {
    margin-left: 0;
    margin: 0 0 20px -10px;
}


.generalActions .glyphicon {
    color: #fff;
    padding: 10px;
    float: left;
}

    .generalActions .glyphicon:hover, .generalActions .glyphicon:focus {
        color: #23527C;
    }

.generalActions > div > div {
    padding-right: 5px;
}


.generalActionsTemplate {
    background-color: #337AB7;
    margin: 20px 0 20px -10px;
}

    .generalActionsTemplate .glyphicon {
        color: #fff;
        padding: 10px;
        float: left;
    }

        .generalActionsTemplate .glyphicon:hover, .generalActionsTemplate .glyphicon:focus {
            color: #23527C;
        }

    .generalActionsTemplate > div > div {
        padding-right: 5px;
    }

.generalActionDownloadbutton {
    background-color: #337AB7;
}

    .generalActionDownloadbutton .glyphicon {
        color: #fff;
        padding: 10px;
        float: left;
    }

        .generalActionDownloadbutton .glyphicon:hover, .generalActionDownloadbutton .glyphicon:focus {
            color: #23527C;
        }

    .generalActionDownloadbutton > div > div {
        padding-right: 5px;
    }


#MyActions {
    margin-left: 0;
}

.MyCustomActions {
    margin-left: 0;
}

.isTemplate #MyActions {
    background-color: lightcoral;
}

.isTemplate .lv-position-box {
    margin-top: 1em;
    width: 200px;
}

.isTemplate .myProjectProductImageCount {
    margin-top: 1em;
    width: 130px;
}

.isTemplate .customProductCount > div > span {
    margin-top: 0;
}

.hint-text {
    color: #fff;
    font-size: 2em;
    margin-left: 10px;
}

div#ProductName {
    float: left;
    font-size: 20px;
    color: #fff;
    padding-right: 0;
    padding-left: 10px;
    padding-bottom: 10px;
    padding-top: 10px;
    margin-right: 40px;
}


.row.downloads-project {
    margin-top: 0;
    display: block;
}

.checkbox-label-download-project {
    padding-top: 4px;
}

.myProjectProductDescription:hover {
    opacity: 0.8;
}

.myProjectProduct {
}

.myProjectProductImage {
    float: left;
    min-width: 100px;
    margin-right: 10%;
    position: relative;
}

    .myProjectProductImage:hover .display-toggle:first-of-type, .product-image:hover .display-toggle:first-of-type, .details-product-image:hover .display-toggle:first-of-type, .header-product-image:hover .display-toggle:first-of-type {
        display: block;
    }

    .myProjectProductImage .glyphicon, .product-image .glyphicon, .details-product-image .glyphicon, .header-product-image .glyphicon {
        top: calc(50% - 16px);
        left: calc(50% - 16px);
        color: #003655;
        position: absolute;
        opacity: 0.6;
    }

.product-image.myProjectProductImage {
    height: 100px;
    width: 100px;
    margin-bottom: 5px;
}

.myProjectProductImageCount {
    float: left;
    margin-left: 0.7em;
    margin-top: 0.5em;
}

    .myProjectProductImageCount input, .customProductCount > div {
        width: 60px;
        text-align: right;
    }

.myProjectCustomPrice {
    margin-left: 0.7em;
    margin-top: 0.5em;
}

    .myProjectCustomPrice input {
        text-align: right;
        width: 77px;
    }

.isTemplate .customProductCount > div {
    float: left;
}

.customPrice > div {
    margin-top: 0.5em;
}

.customPrice {
    float: left;
}

.currency-symbol {
    margin-top: 6.3px;
    margin-left: 0.3em;
    display: inline-block;
}

input.custom-input {
    height: 30px;
    border: 1px solid rgba(0,0,0,0);
    font-weight: normal;
    background-color: #F7F8FA;
    color: #0066a1;
    display: inline-block;
    margin-left: 0.7em;
}

.custom-image {
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 2px dashed #858585;
    background-color: #F7F8FA;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

    .custom-image:hover {
        border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        border: 2px dashed #858585;
        background-color: #d3deed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
    }

.ProjectCustom-image {
    width: 283px;
    height: 200px;
    border-radius: 10px 10px 10px 10px;
    -moz-border-radius: 10px 10px 10px 10px;
    -webkit-border-radius: 10px 10px 10px 10px;
    border: 2px dashed #858585;
    background-color: #F7F8FA;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
}

    .ProjectCustom-image:hover {
        width: 283px;
        height: 200px;
        border-radius: 10px 10px 10px 10px;
        -moz-border-radius: 10px 10px 10px 10px;
        -webkit-border-radius: 10px 10px 10px 10px;
        border: 2px dashed #858585;
        background-color: #d3deed;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        text-align: center;
    }

input.custom-input:hover {
    border: 1px solid #337AB7;
    border-style: inset;
}

.vorschau {
    height: 100px;
    border: 1px solid #000;
    margin: 10px 5px 0 0;
}

output {
    display: block;
}

.philipsProduct-comment-edit {
    cursor: pointer;
}

textarea.custom-input-description {
    border: 1px solid rgba(0,0,0,0);
    font-weight: normal;
    background-color: #F7F8FA;
    color: #0066a1;
    display: block;
    width: 100%;
    max-width: 100%;
}

    textarea.custom-input-description:hover {
        border: 1px solid #337AB7;
        margin-right: -2px;
        border-style: inset;
    }

.myProjectCustomPrice input:hover {
    margin-right: 0;
}

.myProjectProductImageCount input:hover {
    margin-right: 0;
}

input.custom-input:focus {
    outline: 0;
    border: 1px solid #66afe9;
    -webkit-box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
    box-shadow: inset 0 1px 1px rgba(0,0,0,.075),0 0 8px rgba(102,175,233,.6);
}

.myProjectProductImageCount input:focus, .myProjectCustomPrice input:focus {
    margin-right: 0;
}

.customProductCount > div > span {
    margin-top: 9px;
    display: block;
}

.lv-position-box {
    float: left;
    margin-top: 0.5em;
}

    .lv-position-box input {
        width: 115px;
    }

.myProjectProductDescription {
    float: left;
    min-width: 200px;
}

.area {
    margin-top: 15px;
}

.areaHeader {
    width: 100%;
    position: relative;
    display: table;
    margin-bottom: 15px;
    background-color: rgb(51, 122, 183);
    color: #fff;
}

.areaHeaderDescription {
    display: table-cell;
    padding: 10px;
}

    .areaHeaderDescription a {
        color: #fff;
    }

        .areaHeaderDescription a:hover, .areaHeaderDescription a:focus, .areaHeaderDescription a:active {
            text-decoration: none;
            text-shadow: 0 0 4px rgba(0,0,0,0.3);
        }

.areaHeaderActions {
    float: right;
    display: table-cell;
    padding-top: 4px;
}

    .areaHeaderActions .glyphicon {
        padding: 5px 0 5px 20px;
        float: right;
        margin-right: -5px;
        color: #fff;
    }

        .areaHeaderActions .glyphicon:hover, .areaHeaderActions .glyphicon:focus {
            color: #23527C;
        }

.myAreaProductActions {
    padding-right: 7px;
}

    .myAreaProductActions div {
        float: left;
        margin: 5px;
    }

    .myAreaProductActions .dropdown-download .glyphicon {
        color: #337AB7;
    }

        .myAreaProductActions .dropdown-download .glyphicon:hover, .myAreaProductActions .dropdown-download .glyphicon:focus {
            color: #23527C;
        }

.export-button {
    cursor: pointer;
}

.dropdown-export {
    float: left;
}

.myArea .myAreaHeader {
    margin-bottom: 15px;
    background-color: #337AB7;
    color: #fff;
}

.display-toggle {
    display: none;
}

#testboxnewproduct {
    width: 50px;
    height: 50px;
}

.margin-top-1 {
    margin-top: 1px;
}

.margin-bottom-10 {
    margin-bottom: 10px;
}

.margin-bottom-20 {
    margin-bottom: 20px;
}

.margin-bottom-minus20 {
    margin-bottom: -20px;
}

.margin-top-10 {
    margin-top: 10px;
}

.margin-top-5 {
    margin-top: 5px;
}

.margin-top-20 {
    margin-top: 20px;
}

.margin-top-minus20 {
    margin-top: -20px;
}

.margin-top-25 {
    margin-top: 25px;
}

.margin-top-50 {
    margin-top: 50px;
}

.margin-top-100 {
    margin-top: 100px;
}

.margin-right-15 {
    margin-right: 15px;
}

.margin-right-30 {
    margin-right: 30px;
}

.margin-left-minus-13 {
    margin-left: -13px;
}

.margin-left-minus-50 {
    margin-left: -50px;
}

.height-20px {
    height: 20px;
}

.height200pxMailer {
    height: 200px;
}

.width-100pc {
    width: 100%;
}

.width-200px {
    width: 200px;
}

.width-170px {
    width: 170px;
}

.width-115px {
    width: 115px;
}

.width-60px {
    width: 60px;
}

.width-77px {
    width: 77px;
}

.width-50pc {
    width: 50%;
}

.width-300px {
    width: 300px;
}

.btn.width-300px {
    width: 300px;
}

.btn.width-170px {
    width: 170px;
}

.btn.width-140px {
    width: 140px;
}

.btn.width-130px {
    width: 130px;
}

.btn.width-120px {
    width: 120px;
}

.btn-number {
    width: 40px;
}

.float-left {
    float: left;
}

.float-right {
    float: right;
}

.clear-both {
    clear: both;
}

.display-block {
    display: block;
}

.display-inherit {
    display: inherit;
}

.display-none {
    display: none !important;
}

#ErrorBlock {
    padding: 20px;
}

#ErrorHeader {
    margin-bottom: 10px;
}

.color-red {
    color: #FF0000;
}
.backgroundcolor-lightblue {
    background-color: #b9d2f5;
}

.unselectable {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}

    .unselectable:hover,
    .unselectable:active {
        cursor: default;
    }

.pointer {
    cursor: pointer;
}

.dropdown-download {
    float: left;
}

#Product-Index .dropdown-download {
    float: right;
}

#Product-Index div.product-actions {
    padding-right: 22px;
}

.dropdown-export .dropdown-menu > li > span {
    font-size: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
}

.certificate {
    float: left;
    margin: 0.5em 1em 0 0;
}

.certificates {
    display: inline-block;
}

.tab-content {
    margin-top: 20px;
}

.characteristica {
    display: table-cell;
    position: relative;
    padding: 0 15px 5px 15px;
    float: left;
}

    .characteristica.col-md-4 {
        width: 32%;
    }

.dbText-whitespace-pre-line {
    white-space: pre-line;
}


#Lichttechnik img {
    width: 50%;
}

.productDataLumsDetailsSlider > .owl-stage-outer {
    width: 99%;
}

table.viewTable {
    border-radius: 4px;
}

.viewTable .viewHeader td {
    background-color: #F7F8FA;
}

.viewTable td span.h3 {
    margin-left: 5px;
}

.viewTable td a {
    width: 100%;
    margin: auto;
    display: block;
    padding-top: 12px;
    float: none;
    margin-right: 5px;
    padding-left: 10px;
}

.viewTable td span {
    width: 100%;
    margin: auto;
    display: block;
    padding-top: 12px;
    float: none;
    margin-right: 5px;
    padding-left: 10px;
}

.viewTable td {
    height: 50px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    vertical-align: middle;
    padding-left: 0;
    padding-right: 10px;
}

.viewHeader {
    margin-bottom: 1em;
}

.viewOption td.active {
    background-color: #337AB7;
}

    .viewOption td.active a {
        color: #fff;
    }

.tenderTextOptionLink:hover {
    cursor: pointer;
}

#ProjectResults {
    width: 100%;
}

.customerLogoWrapper {
    max-height: 200px;
    max-width: 283px;
    height: 200px;
    width: 283px;
}

#CustomerLogo {
    margin-bottom: 1em;
}

    #CustomerLogo img {
        max-height: 100%;
        max-width: 100%;
        height: auto;
        width: auto;
    }

#downloadActions {
    overflow-y: hidden;
    border: none;
    margin-bottom: 1em;
}

    #downloadActions:focus {
        outline: 0;
    }

    #downloadActions > option {
        padding: 0.5em;
    }

.vertical-no-padding {
    padding-left: 0;
    padding-right: 0;
}

.left-no-padding {
    padding-left: 0;
}

.right-no-padding {
    padding-right: 0;
}

#Produktdaten .filterGroups {
    margin: 7px 0 0 15px;
}

    #Produktdaten .filterGroups > span.h4 {
        font-size: 1.1em;
        font-weight: 600;
    }

#Produktdaten .row.certificates {
    margin-left: auto;
}

#Produktdaten .row {
    margin-top: 5px;
}

#Produktdaten hr {
    margin-bottom: 0;
}

.nav-tabs.nav-justified > .active > a {
    font-weight: 600;
    color: #fff;
    font-size: 1.1em;
    background-color: #337AB7;
}

.nav-tabs.nav-justified a:hover {
    color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justified a:focus {
    color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justified a:active {
    color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justified > .active > a:hover {
    color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justified > .active > a:focus {
    color: #fff;
    background-color: #337AB7;
}

.nav-tabs.nav-justified > .active > a:active {
    color: #fff;
    background-color: #337AB7;
}

.nav-tabPosition {
    width: 50%;
    text-align: center;
}

.existent-background {
    background-color: #F4F8FE;
    border-radius: 7px 7px 1px 1px;
    font-weight: bold;
    color: #23527C;
}

.nav-tabs.nav-justifiedCustom > .active > a {
    font-weight: 600;
    font-size: 1.1em;
    background-color: #dbe0e4;
    color: #23527C;
    border: 1px solid #AFB3B6;
    border-bottom-color: transparent;
}

.nav-tabs.nav-justifiedCustom a:hover {
    color: #fff;
    background-color: #337AB7;
}

.nav-tabs.nav-justifiedCustom a:focus {
    color: #fff;
    background-color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justifiedCustom a:active {
    color: #fff;
    background-color: #337AB7;
    background-color: #F4F8FE;
}

.nav-tabs.nav-justifiedCustom > .active > a:hover {
    color: #fff;
    background-color: #337AB7;
}

.nav-tabs.nav-justifiedCustom > .active > a:focus {
    background-color: #dbe0e4;
    color: #23527C;
}

.nav-tabs.nav-justifiedCustom > .active > a:active {
    color: #337AB7;
    background-color: #F4F8FE;
}

#Contact p {
    margin: 5px 0 15px;
}

.checkbox-label a {
    text-decoration: underline;
}

.popover {
    width: auto;
    height: auto;
    padding-left: 10px;
}

.popover-content {
    padding: 9px 14px 9px 0;
}

    .popover-content > ul {
        margin-bottom: 0;
    }

        .popover-content > ul li {
            margin-bottom: 5px;
        }

.popover li {
    margin-left: -20px;
}



#Is-template-box {
    margin-top: 5em;
}

    #Is-template-box input[type="radio"] {
        margin-right: 10px;
    }

    #Is-template-box > .col-md-4, #Is-template-box > .col-md-8 {
        padding-left: 0;
    }

.downloads label {
    font-weight: 500;
    user-select: none;
}

    .downloads label.disabled {
        color: #DADCDF;
    }

input.btn.btn-primary.dropdown-download {
    width: 100%;
    height: 50px;
}

ul.dropdown-menu#dropdown-multi-download {
    min-width: 250px;
}

div#Project-Description {
    margin-top: -10px;
    margin-bottom: 20px;
}

.overflow-y-hidden {
    overflow-y: hidden;
}

.overflow-y-auto {
    overflow-x: auto;
}

.overflow-x-hidden {
    overflow-x: hidden;
}

.overflow-x-auto {
    overflow-x: auto;
}

.product-prize {
    margin-left: 0.7em;
    width: 116px;
    text-align: right;
}

button.close {
    z-index: 122;
    position: absolute;
    top: 0;
    right: 0;
    font-size: 50px;
}

    button.close:focus {
        outline: none;
    }

#Memorize-All {
    margin-right: 2px;
    color: #EAEDF1;
}

    #Memorize-All.check {
        color: #449d44;
    }

div#Memorize-All-Box {
    margin-bottom: 10px;
}

/*#Multi-download.is_stuck{
    padding-top:50px;
}*/

span.forgot-pw {
    margin-left: 3em;
    padding-bottom: 1em;
    display: block;
}

.button-box {
    position: relative;
}

#Download-Button-Overlay {
    width: 125px;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 52px;
    top: -1px;
    left: -1px;
    background-color: #fff;
    z-index: 107;
    opacity: 0.8;
    display: block;
    cursor: default;
}

#Product-Index #Download-Button-Overlay, .breadcrumb.sticky #Download-Button-Overlay {
    width: 251px;
}

#Download-Pending {
    display: block;
    position: absolute;
    right: 56px;
    bottom: 10px;
}

#Product-Index #Download-Pending, .breadcrumb.sticky #Download-Pending {
    right: 120px;
    bottom: 17px;
}

/* ------------------------------------*/
/* ---------->>> BUTTONS <<<-----------*/
/* ------------------------------------*/

.btn {
    width: 100px;
}

.btn-download {
    width: 120px;
    margin-top: 1em;
}

input[type="checkbox"] {
    width: 20px;
    height: 20px;
}

.underline {
    text-decoration: underline;
}

.btn-primary {
    color: #ffffff;
    background-color: #0066A1;
}

.smallLabel > label {
    font-weight: 600;
    font-size: 0.8em;
    padding-right: 5px;
}

/* ---------------------------------------------*/
/* ---------->>> HEADER / SEARCH <<<------------*/
/* ---------------------------------------------*/

.header {
    height: auto;
    background-repeat: repeat-x;
    background: -webkit-gradient(linear,left top, right bottom,color-stop(0, #9db5d7),color-stop(50%, #d3deed),to(#fff));
    background: linear-gradient(to bottom right,#9db5d7 0,#d3deed 50%,#fff 100%);
}

.headerBG {
    background-repeat: repeat-x;
    background: -webkit-gradient(linear,left top, right bottom,color-stop(0, #9db5d7),color-stop(50%, #d3deed),to(#fff));
    background: linear-gradient(to bottom right,#9db5d7 0,#d3deed 50%,#fff 100%);
}

    .headerBG.ContentPage {
        background: none;
        background-position-x: center;
        background-position-y: 100%;
        background-repeat: no-repeat;
        background-size: cover;
    }

        .headerBG.ContentPage .header {
            background: none;
        }

    .headerBG.ProductsTreenode.Index {
        background: none;
        background: url("Images/Philips-Services-Klosterneuburg-Stadtbeleuchtung-L.jpg") no-repeat center 100%;
        background-size: cover;
    }

        .headerBG.ProductsTreenode.Index .header {
            background: none;
        }

    .headerBG.Project.Templates {
        background: none;
        background: url("Images/Projektbereich.jpg") no-repeat center 100%;
        background-size: cover;
    }

        .headerBG.Project.Templates .header {
            background: none;
        }

    .headerBG.Project.Index {
        background: none;
        background: url("Images/Projektbereich.jpg") no-repeat center 100%;
        background-size: cover;
    }

        .headerBG.Project.Index .header {
            background: none;
        }

    .headerBG.Project.MyProject {
        background: none;
        background: url("Images/Projektbereich.jpg") no-repeat center 100%;
        background-size: cover;
    }

        .headerBG.Project.MyProject .header {
            background: none;
        }

.HeaderElBridge {
    float: right;
    width: 200px;
    background: white;
    padding: 10px;
}

div#HeadingContainer {
    margin-left: 215px;
    padding-top: 80px;
    margin-bottom: 50px;
}

div#Heading h1 {
    margin: 0;
    color: #fff;
    letter-spacing: -2px;
    font-size: 50px;
    line-height: 1.3;
    font-weight: normal;
    white-space: pre-line;
}

div#SearchForm {
    /*max-width: 550px;*/
}

    div#SearchForm h2 {
        margin: 0;
        color: #fff;
        letter-spacing: -2px;
        font-size: 50px;
        line-height: 100px;
        font-weight: normal;
    }

#SearchSpecs {
    margin-left: 10px;
}

    #SearchSpecs .h3 {
        margin: 0;
        margin-top: 15px;
    }

#SearchTerm {
    color: #fff;
}

#SearchCount {
    color: #fff;
    padding-left: 75px;
}

.btn-search {
    background-color: #0066A1;
    color: #fff;
    height: 49px;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.12) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset;
}

.search-link {
    cursor: pointer;
}

div#Heading h1.font-size-34px {
    font-size: 34px;
}

#HeaderImageBox {
    float: left;
    margin: 5px 30px 20px 0;
    width: 25%;
    min-width: 100px;
}

    #HeaderImageBox img {
        max-width: 100%;
        max-height: 100%;
    }

#HeaderDescription {
    float: left;
    width: 69%;
    height: auto;
    display: inline-block;
    overflow: hidden;
}


.bootstrap-select .dropdown-toggle {
    height: 40px;
}

.width-600px {
    width: 600px;
}


#Search-Information {
    position: absolute;
}

#searchErrorContainer {
    position: absolute;
    margin-top: 35px;
    background: #ffffff;
    background: rgba(255,255,255,0.8);
}

/* -----------------------------------------------*/
/* ---------->>> NAVBAR / DROPDOWN <<<------------*/
/* -----------------------------------------------*/
ul.nav li.dropdown:hover ul.dropdown-menu {
    display: block;
}

.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover,
.navbar-inverse .navbar-nav > .open > a, .navbar-inverse .navbar-nav > .open > a:hover, .navbar-inverse .navbar-nav > .open > a:focus {
    background-color: transparent;
}


.dropdown-menu > li > a, .dropdown-menu > li > div {
    font-size: 12px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 10px;
}

.dropdown-menu > li > a {
    color: #3C3C3C;
}

.dropdown-menu > li > div {
}

.dropdown-menu hr {
    margin: 0;
}

.dropdown-menu {
    background-color: #fff;
    border: 0;
    border-radius: 0;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    padding: 0;
    z-index: 1001;
    max-width: 600px;
}

.dropdown-header {
    padding: 3px 3px;
    font-weight: 600;
    text-decoration: underline;
}

.navbar-inverse {
    background-color: rgba(0,0,0,0);
    background-repeat: repeat-x;
    background: -webkit-gradient(linear,left top, left bottom,color-stop(0, rgba(0,0,0,0.25)),color-stop(20%, rgba(0,0,0,0.25)),to(rgba(0,0,0,0)));
    background: linear-gradient(to bottom,rgba(0,0,0,0.25) 0,rgba(0,0,0,0.25) 20%,rgba(0,0,0,0) 100%);
    border: medium none;
}

    .navbar-inverse .navbar-nav > li > a {
        color: #fff;
        display: inline-block;
        text-align: center;
    }

        .navbar-inverse .navbar-nav > li > a:hover {
            font-weight: 600;
        }

        .navbar-inverse .navbar-nav > li > a:focus {
            font-weight: 600;
            background-color: transparent;
        }

    .navbar-inverse .navbar-nav > li a::after {
        display: block;
        content: attr(title);
        font-weight: bold;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
    }

.navbar {
    margin-bottom: 0;
}

    .navbar > .container .navbar-brand {
        margin-top: -15px;
    }

.navbar-brand {
    padding-bottom: 0;
}

#NavBorder {
    margin: 0 19px 0 200px;
}

/* ----------------------------------------*/
/* ---------->>> BREADCRUMB <<<------------*/
/* ----------------------------------------*/

.breadcrumb {
    background-color: transparent;
    z-index: 9;
    margin-bottom: 0;
    padding: 8px 0;
    margin: 0 5px 0 200px;
    color: #fff;
}

    .breadcrumb > li {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 20%;
        list-style-position: inside;
    }

        .breadcrumb > li > span {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

    .breadcrumb > .active {
        color: #fff;
        font-weight: bold;
        text-shadow: 0 0 4px rgba(0,0,0,0.2);
    }

    .breadcrumb li a {
        color: #fff;
    }

        .breadcrumb li a:hover {
            text-decoration: underline;
        }

    /* ---------->>> STICKY <<<------------*/

    .breadcrumb.sticky {
        position: fixed;
        width: 100%;
        min-height: 47px;
        top: 0px;
        margin: 0;
        padding: 0;
        background: none;
        background-color: #337AB7;
        opacity: 0.95;
        z-index: 105;
        border-radius: 0;
    }

        .breadcrumb.sticky a {
            color: #fff;
        }

        .breadcrumb.sticky .dropdown-download, .breadcrumb.sticky .dropdown-export {
            padding: 5px 10px;
        }

        .breadcrumb.sticky a.glyphicon {
            float: left;
            padding: 5px 10px;
        }

        .breadcrumb.sticky .active, .breadcrumb.sticky li a {
            color: #fff;
        }

        .breadcrumb.sticky .active {
            font-weight: 600;
        }

        .breadcrumb.sticky li:first-of-type {
            margin-top: 20px;
            margin-left: 20px;
        }

#Filter-bar.sticky {
    z-index: 101;
}


    #Filter-bar.sticky div#BadgeBoxOverlay, #Filter-bar.sticky div#ResetFilterOverlay {
        background-color: transparent;
    }

#ActionsBox.sticky .dropdown-menu > li > a, #ProductActionsBox.sticky .dropdown-menu > li > a {
    color: #337ab7;
}

#ActionsBox.sticky .dropdown-menu > li, #ProductActionsBox.sticky .dropdown-menu > li {
    margin: 0;
}

    #ProductActionsBox.sticky .dropdown-menu > li > a:hover, #ActionsBox.sticky .dropdown-menu > li > a:hover {
        text-decoration: none;
    }


.breadcrumb.sticky #ProductActionsBox li:first-of-type {
    margin: 0;
}

.breadcrumb.sticky #ProductActionsBox li a {
    color: #0066A1;
}

/* ------------------------------------------*/
/* ---------->>> MODAL DIALOG <<<------------*/
/* ------------------------------------------*/
.modal-title {
}

.modal-body .row img {
    max-width: 100%;
    max-height: 100%;
}

.modal-body .row {
    margin-bottom: 10px;
    margin-top: 0;
}

/* ----------------------------------------*/
/* ---------->>> HOME/INDEX <<<------------*/
/* ----------------------------------------*/
#slide .item {
}

    #slide .item img {
        width: auto;
        max-width: 100%;
        max-height: 260px;
    }

.block-item {
    overflow: hidden;
    position: relative;
    -webkit-transition: height 1s ease;
    transition: height 1s ease;
    -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
    box-shadow: 0 3px 3px rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    height: 175px;
    width: 275px;
}

.block-item-caption {
    bottom: 0;
    width: 100%;
    color: #fff;
    height: auto;
    position: absolute;
    background-color: #0066a1;
    -webkit-transition: opacity 1s ease;
    transition: opacity 1s ease;
}

.block-item:hover .block-item-caption {
    height: 100%;
    opacity: 0.7;
}

.block-item-caption h3 {
    font-size: 20px;
    margin: 0;
    padding: 5px 0 20px 10px;
}

/* ------------------------------------*/
/* ---------->>> FOOTER <<<------------*/
/* ------------------------------------*/
div#wrap {
    min-height: 100%;
    padding-bottom: 356px;
}

footer {
    height: 356px;
    width: 100%;
    position: absolute;
    padding: 1rem;
    background-color: #0096ee;
    -ms-background-repeat: repeat-x;
    background-repeat: repeat-x;
    background: -webkit-gradient(linear,left top, right bottom,color-stop(0, #003655),color-stop(50%, #0066a1),to(#0096ee));
    background: linear-gradient(to bottom right,#003655 0,#0066a1 50%,#0096ee 100%);
    -ms-filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff003655',endColorstr='#ff0096ee',GradientType=0);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ff003655',endColorstr='#ff0096ee',GradientType=0);
    color: #fff;
    margin-top: 50px;
    bottom: 0;
}

#footerContent {
    height: 100px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 25px;
    text-align: center;
}

#footerClaim > p > a {
    color: #fff;
}

#footerLogoBox {
    margin: 40px;
}

#Copyright {
    color: #dfedf0;
}

/* ------------------------------------------*/
/* ---------->>> OWL-CAROUSEL <<<------------*/
/* ------------------------------------------*/

.owl-carousel .owl-item {
    background-color: #fff;
    padding: 3px;
}

    .owl-carousel .owl-item .item {
        max-width: 260px;
        max-height: 260px;
    }


.owl-theme .owl-controls {
    margin-top: 20px;
    text-align: center;
    -webkit-tap-highlight-color: transparent;
}

    .owl-theme .owl-controls .owl-nav [class*=owl-] {
        color: #fff;
        font-size: 14px;
        margin: 5px;
        padding: 4px 7px;
        background: #d6d6d6;
        display: inline-block;
        cursor: pointer;
        border-radius: 3px;
    }

        .owl-theme .owl-controls .owl-nav [class*=owl-]:hover {
            background: #869791;
            color: #fff;
            text-decoration: none;
        }

    .owl-theme .owl-controls .owl-nav .disabled {
        opacity: .5;
        cursor: default;
    }

.owl-theme .owl-dots .owl-dot {
    display: inline-block;
    -ms-zoom: 1;
    zoom: 1;
    *display: inline;
}

    .owl-theme .owl-dots .owl-dot span {
        width: 10px;
        height: 10px;
        margin: 5px 7px;
        background: #d6d6d6;
        display: block;
        -webkit-transition: opacity 200ms ease;
        transition: opacity 200ms ease;
        border-radius: 30px;
    }

    .owl-theme .owl-dots .owl-dot.active span, .owl-theme .owl-dots .owl-dot:hover span {
        background: #869791;
    }

/* -----------------------------------*/
/* ---------->>> TILES <<<------------*/
/* -----------------------------------*/
.tile {
    /*Posiitoning*/
    float: left;
    position: relative;
    /*Display & Box Model*/
    display: table-cell;
    width: 100%;
    height: 130px;
    border-radius: 4px;
    margin: 5px;
    /*Color*/
    background-color: #0066a1;
    color: #fff;
    /*Text*/
    text-align: center;
    vertical-align: middle;
    /*Other*/
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
}

    .tile > span {
        line-height: 130px;
    }

    .tile:hover {
        opacity: 0.8;
        cursor: pointer;
        -webkit-box-shadow: 0 3px 8px rgba(0,0,0,0.4);
        box-shadow: 0 3px 8px rgba(0,0,0,0.4);
    }

    .tile > .clear {
        position: absolute;
        right: 3px;
    }

        .tile > .clear.doCollapse {
            bottom: 3px;
        }

        .tile > .clear.add {
            top: 3px;
        }

.clear {
    padding: 0;
    border-radius: 4px;
    background-color: transparent;
    color: #fff;
    border: 0;
}

    .clear:hover {
        color: #061738;
    }

    .clear:active, .clear:focus {
        outline: none;
        text-decoration: none;
    }

.tileDropDown {
    height: auto;
    margin-top: 5px;
    width: 65%;
}

ul.tileDropDown > li {
    list-style: none;
    margin-bottom: 5px;
}

    ul.tileDropDown > li > a {
        color: #fff;
        font-size: 1.3em;
    }

        ul.tileDropDown > li > a:hover, ul.tileDropDown > li > a:focus, ul.tileDropDown > li > a:active {
            text-decoration: none;
            background-repeat: repeat-x;
            background: -webkit-gradient(linear,left top, right bottom,color-stop(0, rgba(157,181,215,0.2)),color-stop(50%, rgba(211,222,237,0.2)),to(rgba(255,255,255,0.2)));
            background: linear-gradient(to bottom right,rgba(157,181,215,0.2) 0,rgba(211,222,237,0.2) 50%,rgba(255,255,255,0.2) 100%);
            border-radius: 4px;
        }

.actions {
    margin-right: 15px;
}

/* -----------------------------------*/
/* ---------->>> INPUT <<<------------*/
/* -----------------------------------*/

/*Let the number input in firefox look like a textfield, becuse spinners won't hide*/
input[type=number] {
    -moz-appearance: textfield;
}
    /* no spin-buttons in ie and chrome*/
    input[type=number]::-webkit-inner-spin-button,
    input[type=number]::-webkit-outer-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }

/* ------------------------------------*/
/* --------->>> Sortable <<<-----------*/
/* ------------------------------------*/

.inner-sortable {
    min-height: 10px;
}

.ui-sortable-helper {
    background-color: rgb(240, 240, 240);
    background-color: rgba(51, 122, 183, 0.10);
    opacity: 0.8;
}

    .ui-sortable-helper > .row {
        margin-top: 0;
    }

.ui-sortable-placeholder {
    background: rgba(51, 122, 183, 0.1);
    visibility: visible !important;
}

/* ------------------------------------*/
/* ---------->>> FILTER <<<------------*/
/* ------------------------------------*/
#FilterBox {
    margin-top: -2px;
    background: #fff;
    -webkit-box-shadow: 0 0 8px rgba(0, 0, 0, 0.12) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset;
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.12) inset, 0 0 1px rgba(0, 0, 0, 0.2) inset;
    position: relative;
    clear: both;
}

    #FilterBox hr {
        margin-top: 3px;
        margin-bottom: 3px;
        width: 98%;
    }

    #FilterBox .row {
        margin-top: 0;
    }

    #FilterBox .h3 {
        font-size: 18px;
        margin-left: 10px;
    }

    #FilterBox .h4 {
        font-size: 15px;
    }

    #FilterBox.in {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-direction: column;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        flex-direction: column;
    }

    #FilterBox header {
        margin: 0;
        margin-left: 3px;
    }

        #FilterBox header span.glyphicon {
            float: right;
            padding-right: 5px;
        }

#FilterBoxOverlay {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    opacity: 0.7;
    top: 0px;
}

    #FilterBoxOverlay > .center-image {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

#FilterButtonOverlay {
    width: 122px;
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 52px;
    top: -1px;
    left: -1px;
    background-color: #fff;
    z-index: 107;
    opacity: 0.8;
    display: block;
    cursor: default;
}

    #FilterButtonOverlay > img {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

.filter-group-box {
    width: 100%;
    margin: 0;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 0 10px;
}

.btn-filter.collapsed {
    margin-top: 0;
}



#Filter-bar {
    display: table;
    width: 100%;
}

#FilterButton {
    width: 122px;
    height: 52px;
    position: relative;
    display: table-cell;
    vertical-align: baseline;
}

#FilterBox header.charHeader {
    margin-top: 5px;
}


.btn-filter {
    width: auto;
    height: 50px;
    border-radius: 0;
    color: #fff;
    background-color: #337AB7;
}

    .btn-filter:hover, .btn-filter:active, .btn-filter:focus {
        color: #fff;
        background-color: #003655;
    }

button.filterBadge {
    display: table-cell;
    float: left;
    margin-left: 8px;
    margin-bottom: 2px;
    border-radius: 5px;
    border: 1px solid #fff;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding: 5px;
    background-color: #337ab7;
    color: #fff;
}

div#BadgeBoxOverlay {
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 107;
    opacity: .7;
    cursor: default;
}

button.filterBadge > span {
    padding-left: 4px;
}

#MoreFiltersButton > a:after {
    content: "weniger Filter";
}

#MoreFiltersButton > a.collapsed:after {
    content: "erweiterte Filter";
}

#totalProductCount {
    padding-left: 0;
    padding-right: 0;
    margin-left: 5px;
}

#ResetFilter {
    float: right;
}

    #ResetFilter:hover {
        text-decoration: none;
        color: #23527c;
        cursor: pointer;
    }

div#ResetFilterBox {
    float: right;
}


div#ResetFilterOverlay {
    position: absolute;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    height: 100%;
    width: 100%;
    background-color: #fff;
    z-index: 107;
    opacity: .7;
    cursor: default;
}

.char_value_node {
    padding-bottom: 3px;
    padding-left: 0;
    padding-right: 0;
}

button.filter {
    margin: 0;
    padding: 0;
    cursor: pointer;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    min-height: 30px;
    display: table;
    text-decoration: none;
    text-align: left;
    border-radius: 5px;
    position: relative;
}

    button.filter.unchecked {
        color: #337AB7;
        border: 1px solid #E5E5E5;
        background-color: #fff;
    }

    button.filter.checked {
        color: #fff;
        background-color: #337AB7;
        border: 1px solid transparent;
    }

.characteristica > header {
    color: #003655;
    font-size: 1.25em;
    margin-bottom: 5px;
}

button.filter:active, button.filter:focus {
    outline: 0 none;
}

.filter-temp-button {
    display: block;
    height: 100%;
    margin-bottom: 3px;
}

.btn-group > .btn.unchecked :first-child:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
}

.btn-group > .btn.unchecked .filter-remove {
    display: none;
}

.filter-temp-button:hover {
    -webkit-box-shadow: 0 3px 8px rgba(0,0,0,0.4);
    box-shadow: 0 3px 8px rgba(0,0,0,0.4);
}

.filter-temp-button > span.filter-button-image {
    display: inline-block;
    vertical-align: middle;
    position: absolute;
    float: left;
    white-space: normal;
    min-width: 35px;
    width: 12%;
    height: 100%;
    left: 0;
    top: 0;
}

    .filter-temp-button > span.filter-button-image img {
        width: 100%;
        height: 100%;
        border-radius: 5px;
    }

.filter-temp-button > span.filter-button-text {
    display: inline-block;
    position: relative;
    white-space: normal;
    width: 80%;
    margin-left: 50px;
}

.filter-temp-button.hasNoProducts {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
    color: #fff !important;
    border: 1px solid transparent !important;
    background-color: #808080 !important;
}

.filter-temp-button.allProducts {
    cursor: not-allowed;
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: 0.5;
    color: #fff !important;
    border: 1px solid transparent !important;
    background-color: #337ab7 !important;
}

/* ---------->>> TEMPLATE 2 <<<------------*/
.filter-temp-button.template-2 > span.filter-button-text {
    margin: 0;
    padding: 0 5px;
    width: 100%;
}

/* ---------->>> TEMPLATE 3 <<<------------*/
.char_value_node.template-3 {
    padding-bottom: 0;
}

.filter-temp-button.template-3 > span.filter-button-text {
    margin: 2px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    padding-left: 5px;
    margin-left: 0;
}

button.filter.template-3 {
    min-height: 35px;
    min-width: 0;
    float: left;
}

/* ---------->>> DYNAMIC COLUMNS <<<------------*/
.char_value_node.col-md-6 button.filter {
    min-width: 0;
}

.char_value_node.col-md-6 {
    padding: 0 5px 3px 0;
}


/* --------------------------------------*/
/* ---------->>> DROPZONE <<<------------*/
/* --------------------------------------*/

.dropzoneWrapper {
    position: relative;
}

.dropzone {
    width: 283px;
    height: 200px;
    z-index: 1;
}

img#projectImg {
    max-width: 100%;
    max-height: 100%;
    width: auto;
    height: auto;
}

.projectImageWrapper {
    height: 200px;
    width: 283px;
    position: absolute;
    top: 0;
    left: 0;
    background-color: #fff;
}

button#deleteProjectImageBt {
    width: 110px;
}

button#deleteCustomProductImageBt {
    width: 110px;
}

/* ------------------------------------*/
/* -------->>> Configurator <<<--------*/
/* ------------------------------------*/

#ProductSectionBoxOverlay {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    opacity: 0.7;
    top: 0px;
}

    #ProductSectionBoxOverlay > .center-image {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

#DownloadSectionBoxOverlay {
    width: 100%;
    position: absolute;
    height: 100%;
    background-color: #fff;
    z-index: 99;
    opacity: 0.7;
    top: 0px;
}

    #DownloadSectionBoxOverlay > .center-image {
        position: absolute;
        left: 50%;
        top: 50%;
        -webkit-transform: translate(-50%, -50%);
        transform: translate(-50%, -50%);
    }

/* -------------------------------------------*/
/* -------------------------------------------*/
/* ---------->>> MEDIA QUERIES <<<------------*/
/* -------------------------------------------*/
/* -------------------------------------------*/



@media (max-width: 768px) {
    div#HeadingContainer {
        margin-left: 0;
        padding-top: 60px;
    }

    div#Heading h1 {
        line-height: 80px;
    }

    .navbar-toggle {
        border: 0;
    }

        .navbar-toggle.collapsed:hover, .navbar-toggle.collapsed:active, .navbar-toggle.collapsed:focus,
        .navbar-toggle:hover, .navbar-toggle:active, .navbar-toggle:focus {
            background-color: #3D81BB;
        }

    .navbar-inverse .navbar-toggle:hover, .navbar-inverse .navbar-toggle:focus {
        background-color: #3D81BB;
    }

    .navbar-inverse .navbar-collapse, .navbar-header {
        border: 0;
    }

    .navbar-brand img {
        z-index: 99;
    }

    ul.nav {
        position: relative;
        z-index: 100;
    }

    .navbar-toggle + a.navbar-brand {
        display: none;
    }

    .navbar-toggle.collapsed + a.navbar-brand {
        display: block;
    }


    a.navbar-brand img {
        width: 75%;
    }

    .breadcrumb, #NavBorder {
        margin-left: 135px;
    }
}


@media (max-width: 480px) {
    div#HeadingContainer {
        padding-top: 0;
    }

    div#Heading h1 {
        font-size: 2.5em;
        line-height: 50px;
    }

    #footerLogoBox {
        margin: 40px 0 40px 0;
    }

    footer {
        height: 400px;
    }

    a.navbar-brand img {
        width: 50%;
    }

    .breadcrumb {
        margin-top: 30px;
        margin-left: 0;
    }

        .breadcrumb li {
            display: block;
        }

    #NavBorder {
        display: none;
    }

    .areaHeaderActions .glyphicon {
        padding-left: 15px;
    }
}

@media(max-width:599px) {
    .homeSlider {
        width: 275px !important;
    }
}

@media(max-width:767px) {
    #slide.productDataLumsDetailsSlider {
        width: 250px;
    }
}

@media(min-width:768px) {
    .container > .navbar-collapse {
        margin-left: 165px;
        margin-right: -15px;
    }

    .navbar-right {
        margin-right: 0;
    }

    .navbar-nav {
        margin-left: 0;
    }
}

@media(min-width: 992px) {
    .navbar-nav {
        margin-left: -20px;
    }
}


@media(min-width:768px) and (max-width: 1199px) {
    .myProjectProductImageCount {
        margin-left: 0;
    }

    .myProjectCustomPrice {
        margin-left: 0;
    }
}

@media(max-width:360px) {
    .myProjectProductImageCount {
        margin-left: 0;
    }

    .myProjectCustomPrice {
        margin-left: 0;
    }
}

tbody[data-groupname]::before {
    content: attr(data-groupname);
    font-weight: 600;
}


@media(max-width:991px) {
    .led-badge {
        right: auto;
        left: 100px;
    }
}


/* -------------------------------------------*/
/* ---------->>> JQuery UI <<<----------------*/
/* -------------------------------------------*/

table.ui-datepicker-calendar tr td a, table.ui-datepicker-calendar tr td p, table.ui-datepicker-calendar tr td span {
    min-height: 0;
}

/* ---------------------------------------------*/
/* ---------->>> COOKIE HINT <<<----------------*/
/* ---------------------------------------------*/

.p-cookiebar {
    position: fixed;
    bottom: 0;
    left: 0;
    background-color: darkblue;
    padding: 20px;
    text-align: center;
    color: white;
    z-index: 100;
}

/* --------------------------------------------------*/
/* ---------->>> BOOTSTRAP-SELECT <<<----------------*/
/* --------------------------------------------------*/
/*.bootstrap-select:not([class*=col-]):not([class*=form-control]):not(.input-group-btn) {
    width: 300px;
}*/
/* --------------------------------------------------*/
/* ---------------->>> PROJECT <<<-------------------*/
/* --------------------------------------------------*/

.PhilipsProductAndCustomProductRowsFlexParent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.PhilipsProductAndCustomProductRowsFlexChildImage {
    padding-left: 30px;
    -ms-flex-item-align: center;
    align-self: center;
    width: 15%;
}

.PhilipsProductAndCustomProductRowsFlexChildDescription {
    padding-left: 40px;
    -ms-flex-item-align: center;
    align-self: center;
    width: 70%;
}

.PhilipsProductAndCustomProductRowsFlexChildActions {
    padding-left: 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: flex-start;
justify-content:flex-start;*/
    width: 15%;
    height: 100%;
}

.InputFieldsFlexParent {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: baseline;
    -ms-flex-align: baseline;
    align-items: baseline;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    width: 100%;
    height: 100%;
}

.InputPositionFlexChild {
    -ms-flex-item-align: center;
    align-self: center;
}

.InputAmountFlexChild {
    -ms-flex-item-align: center;
    align-self: center;
    padding-left: 30px;
}

.InputPriceFlexChild {
    -ms-flex-item-align: center;
    align-self: center;
    padding-left: 30px;
}

.SaleStatusInactiveOverlay {
    position: absolute;
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding: 40px;
}

.NotExistendAnymore {
    height: 100%;
    width: 100%;
    background: rgba(255,255,255,0.9);
    z-index: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    padding-bottom: 40px;
    padding-top: 40px;
}

.SaleStatusInactiveOverlay_Font {
    font-size: medium;
    color: #337AB7;
}

.SaleStatusInactiveOverlay_Warning {
    /*padding-right:55px;
       padding-left:-20px;*/
    padding-left: 30px;
    -ms-flex-item-align: center;
    align-self: center;
    width: 15%;
}

.NotExistendAnymoreOverlay_Font {
    padding-left: 15px;
    -ms-flex-item-align: center;
    align-self: center;
    width: 70%;
    font-size: 15px;
}

.NotExistendAnymoreOverlay_Delete {
    padding-left: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    /*align-items: flex-start;
justify-content:flex-start;*/
    width: 15%;
    height: 100%;
}

.SaleStatusInactiveOverlay_Link {
    text-decoration: underline;
}

    .SaleStatusInactiveOverlay_Link:hover {
        text-decoration: underline;
        color: rgb(35, 82, 124);
        cursor: pointer;
    }

.SaleStatusPhaseOutOverlay_Link {
    text-decoration: underline;
    color: red;
}

    .SaleStatusPhaseOutOverlay_Link:hover {
        text-decoration: underline;
        color: rgb(191, 0, 0);
        cursor: pointer;
    }

    .SaleStatusPhaseOutOverlay_Link:focus {
        text-decoration: underline;
        color: rgb(191, 0, 0);
        cursor: pointer;
    }

.SaleStatusPhaseOutWarning {
    color: red;
    font-size: 14px;
}

.ProjectMailToAllMailAdress {
    padding: 4px;
    color: #337ab7;
    border-radius: 8px;
    font-weight: bold;
}

.MailerInput {
    max-width: 450px;
}
/* --------------------------------------------------*/
/* ---------->>> Maintenance Views <<<---------------*/
/* --------------------------------------------------*/


.MaintenanceNavigation_Box {
    background: #337AB7;
    position: relative;
    margin: 10px;
}

div.MaintenanceNavigation_Box[disabled] {
    filter: alpha(opacity=65);
    -webkit-box-shadow: none;
    box-shadow: none;
    opacity: .65;
}

    div.MaintenanceNavigation_Box[disabled] a {
        cursor: not-allowed;
        text-decoration: none;
    }

.MaintenanceNavigation_Box:before {
    content: "";
    display: block;
    padding-top: 100%;
}

.MaintenanceNavigation_Box > a {
    width: 100%;
    height: 100%;
    margin: auto;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-align-items: center;
    -o-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    color: #FFFFFF;
    font-size: 16px;
    font-weight: 600;
}

    .MaintenanceNavigation_Box > a > span {
        margin: auto;
        text-align: center;
    }

/* --------------------------------------------------*/
/* ------------>>> Elbridge<<<---------------*/
/* --------------------------------------------------*/


/* --------------------------------------------------*/
/* ------------>>> Elbridge Header <<<---------------*/
/* --------------------------------------------------*/

.lisa-elbridge-container {
    position: relative;
    height: 0;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}

    .lisa-elbridge-container > div {
        position: relative;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
        -ms-flex-direction: column;
        flex-direction: column;
        -webkit-box-align: end;
        -ms-flex-align: end;
        align-items: flex-end;
    }

        .lisa-elbridge-container > div > label {
            cursor: pointer;
            -ms-user-select: none;
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            margin: 0;
            z-index: 100;
            padding-left: 5pt;
            padding-right: 5pt;
            background: #FFFFFF;
            background: rgba(255,255,255,0.9);
        }

.lisa-elbridge-infocontainer {
    display: none;
    position: relative;
    border-width: 0px;
    border-style: none;
    border-color: #000000;
    top: 0;
    right: 0;
    -webkit-box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    box-shadow: 0 3px 5px rgba(0, 0, 0, 0.3);
    z-index: 99;
    background: white;
    -ms-user-select: none;
    -webkit-user-select: none;
    -moz-user-select: none;
    user-select: none;
}

input[type="checkbox"]:checked ~ .lisa-elbridge-infocontainer {
    display: block;
}

label:hover ~ .lisa-elbridge-infocontainer {
    display: block;
}

.lisa-elbridge-infocontainer:hover {
    display: block;
}

/* --------------------------------------------------*/
/* --------->>> Elbridge LandingPage <<<-------------*/
/* --------------------------------------------------*/


img.ElBridgeOutlook {
    width: 150px;
    height: auto;
}

img.ElBridgeInstruction {
    width: 150px;
    height: auto;
}


.ElbridgeLanding_Box_ContainerOne {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -ms-flex-line-pack: stretch;
    align-content: stretch;
}

.ElbridgeLanding_Box_Instruction {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}

.ElbridgeLanding_Box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    position: relative;
    -webkit-box-pack: start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -ms-flex-line-pack: center;
    align-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    width: 100%;
    height: 100%;
}

    .ElbridgeLanding_Box > span {
        width: 100%;
        height: 100%;
        margin: auto;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-align-items: center;
        -o-align-items: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        color: #FFFFFF;
        font-size: 16px;
        font-weight: 600;
    }

.ElbridgeLandingSaleHeader {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    -webkit-box-align: end;
    -ms-flex-align: end;
    align-items: flex-end;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.ElbridgeOverviewNotificationBorder {
    border: 2px solid lightgrey;
    padding: 10px;
}

/* --------------------------------------------------*/
/* --------->>> Elbridge OverviewPage <<<------------*/
/* --------------------------------------------------*/
div .ElBridgeOverviewTable {
    padding-right: 15px;
    padding-left: 15px;
}


/* --------------------------------------------------*/
/* ---------------->>> Homepage <<<------------------*/
/* --------------------------------------------------*/
a.startpage_Element_link {
}

img.startpage_Element_Image {
    width: 100%;
}

div.ElementBlock {
    border: 1px solid lightgrey;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    padding-top: 20px;
}

.ElementBlock h3 {
    margin-top: 0px;
}

div.ElementBlockSlider {

    margin-top: 10px;
    margin-bottom: 10px;
    padding: 10px;
    padding-top: 20px;
}

.ElementBlockSlider h3 {
    margin-top: 0px;
}

/* --------------------------------------------------*/
/* ----------->>> Maintenance Content <<<------------*/
/* --------------------------------------------------*/

.Content_Maintenance_Overlay {
    padding-right: 10px;

}

.Content_Maintenance_Overlay_inner {
    padding-right: 10px;

}

.Content_Maintenance_Overlay:hover {
    background-color: rgba(220,220,220,0.6);
}

.Content_Maintenance_checkInner, .Content_Maintenance_checkOuter {
    -webkit-transform: scale(1.5);
    transform: scale(1.5);
}

.ContentMaintenanceTable tr td p {
    max-height: 100em;
    overflow: hidden;
}
.ContentMaintenanceTable td a {
    min-height:0;
    display: inline;
}

.Min-Width-250 {
    min-width: 250px;
}

/* --------------------------------------------------*/
/* ----------------->>> Catalog <<<------------------*/
/* --------------------------------------------------*/


.CatalogHeader {
    display: flex;
    flex-wrap: wrap;
}
.CatalogImage {
 width: 100%;
 margin-top:25px;
}
.CatalogSection{
      display: flex;
    align-items: center;
}
.CatalogSectionImage{    
    width:60px
}

